import "../scss/main.scss"

import "./vendors/polyfills"

import "./modules/choices"
import "./modules/flatpickr"
import "./use-cases/forms-actions"
import "./use-cases/paginated-content"

import toggler from "./modules/toggler"
import timer from "./modules/timer"

import Notifications from "./modules/notifications"
import Tabs from "./modules/tabs"

try {
  const notis = new Notifications(".js-notification-wrapper", {
    toggler: ".js-notifications-toggler",
    notificationItems: ".js-notification-item",
    notificationItemDelete: ".js-notification-delete",
    notificationDeleteAll: ".js-notification-delete-all",
    notificationScroll: ".js-notification-scroll",
    notificationLoader: ".js-notification-loader",
    notificationNotReadCounter: ".js-notification-notread-counter"
  })
} catch (e) {}

import Search from "./modules/search"

try {
  const newSearchingParticipants = new Search(".js-search-input-participants", {
    loadable: true,
    loadableClass: "is-loading",
    tableDOM: ".js-table",
    tableContent: ".js-table-content",
    searchButtonDOM: ".js-trigger-search",
    fetchApi: "/api/users",
    pagination: ".js-pagination"
  })
} catch (e) {}
try {
  const newSearchingJudges = new Search(".js-search-input-judges", {
    loadable: true,
    loadableClass: "is-loading",
    tableDOM: ".js-table",
    tableContent: ".js-table-content",
    searchButtonDOM: ".js-trigger-search",
    fetchApi: "/api/judges",
    pagination: ".js-pagination"
  })
} catch (e) {}

try {
  const newSearchingUnitParticipants = new Search(".js-search-input-unit-participants", {
    loadable: true,
    loadableClass: "is-loading",
    tableDOM: ".js-table",
    tableContent: ".js-table-content",
    searchButtonDOM: ".js-trigger-search",
    fetchApi: "/api/unit-participants",
    pagination: ".js-pagination"
  })
} catch (e) {}

import forms from "./modules/forms"
forms.showFormGroup(".js-form-group-hidden")

forms.expandText({ wrapper: ".js-expand-wrapper" })

try {
  toggler({
    toggleButton: ".js-user-profile",
    toggledItem: ".js-user-profile-dropdown"
  })
} catch (e) {}

window.enableEditHandler = forms.enableEditHandler
window.previewPassword = forms.previewPassword

try {
  const tabs = new Tabs(".js-tabs")
} catch (e) {}

try {
  timer("2023-06-01 12:00", ".js-timer-wrapper")
} catch (e) {}
