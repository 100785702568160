const timer = (date, wrapper) => {
  var countDownDate = new Date(date).getTime()

  const wrapperDOM = document.querySelector(wrapper)

  let daysDOM = wrapperDOM.querySelector(".js-timer-days")
  let hoursDOM = wrapperDOM.querySelector(".js-timer-hours")
  let minutesDOM = wrapperDOM.querySelector(".js-timer-minutes")
  let secondsDOM = wrapperDOM.querySelector(".js-timer-seconds")

  var x = setInterval(function() {
    // Get todays date and time
    var now = new Date().getTime()
    // Find the distance between now and the count down date
    var distance = countDownDate - now

    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24))
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
    var seconds = Math.floor((distance % (1000 * 60)) / 1000)

    // Display the result in the element
    if (days) daysDOM.textContent = days
    if (hours) hoursDOM.textContent = hours
    if (minutes) minutesDOM.textContent = minutes
    if (seconds) secondsDOM.textContent = seconds
  }, 1000)
}

export default timer
