import moment from "moment"
import Pagination from "../modules/pagination"
import PhraseSearch from "../modules/phraseSearch"

try {
  const phraseSearch = new PhraseSearch(".js-search-wrapper")
} catch (err) {}

/* -------------------------------------------------------------------------- */
/*                             ADMIN USER TABLE                               */
/* -------------------------------------------------------------------------- */
try {
  const callback = data => {
    const render = item => {
      const row = document.createElement("div")
      row.classList = "table-row"
      row.id = item._id
      row.innerHTML = `
        <div class="table-col col-lg-1 h-ai-c">${item.number}</div>
        <div class="table-col col-lg-4 h-ml-2 h-ai-c">${item.firstname}</div>
        <div class="table-col col-lg-4 h-ml-2 h-ai-c">${item.lastname}</div>
        <div class="table-col col-lg-3 h-ml-2 h-ai-c">${moment(item.birthdate).format("DD.MM.YYYY")}</div>
        <div class="table-col col-lg-5 h-ml-2 h-ai-c">${item.unit}</div>
      `
      return row
    }

    const renderedData = data.items.map(item => {
      return render(item)
    })
    return renderedData
  }

  new Pagination(".js-table-discipline-users", {
    fetchApi: "/admin/api/users",
    renderPlace: ".js-table-content",
    paginationDOM: ".js-pagination",
    renderCallback: callback
  })
} catch (err) {}
/* -------------------------------------------------------------------------- */
/*                           ADMIN DISCIPLINE UNITS                           */
/* -------------------------------------------------------------------------- */
try {
  const callback = data => {
    const render = item => {
      const row = document.createElement("div")
      row.classList = "table-row"
      row.id = item._id
      let minimum = item.count >= 5 ? "" : "is-inactive"
      if (item._id.disciplines == "pilka-siatkowa") minimum = item.count >= 6 ? "" : "is-inactive"
      row.innerHTML = `
        <div class="table-col col-lg-9 h-ai-c">${item._id.unit}</div>
        <div class="table-col col-lg-9 h-ml-2 h-ai-c"><img src="/client/images/check.svg" alt="Check" class="icon icon--xs ${minimum}"> <span class="h-ml-2">${item.count}</span></div>
      `
      return row
    }

    const renderedData = data.items.map(item => {
      return render(item)
    })
    return renderedData
  }

  new Pagination(".js-table-discipline-units", {
    fetchApi: "/admin/api/users/disc-unit-count",
    renderPlace: ".js-table-content",
    paginationDOM: ".js-pagination",
    renderCallback: callback
  })
} catch (err) {}
/* -------------------------------------------------------------------------- */
/*                          ADMIN USER RODO TABLE                             */
/* -------------------------------------------------------------------------- */
try {
  const callback = data => {
    const render = item => {
      const row = document.createElement("div")
      row.classList = "table-row"
      row.id = item._id

      let acceptance1 = item.acceptances[0] ? (item.acceptances[0].accepted ? "" : "is-inactive") : ""
      let acceptance2 = item.acceptances[1] ? (item.acceptances[1].accepted ? "" : "is-inactive") : ""
      let acceptance3 = item.acceptances[2] ? (item.acceptances[2].accepted ? "" : "is-inactive") : ""
      let acceptance4 = item.acceptances[3] ? (item.acceptances[3].accepted ? "" : "is-inactive") : ""

      row.innerHTML = `
        <div class="table-col col-lg-3 h-ai-c">${item.firstname}</div>
        <div class="table-col col-lg-3 h-ml-2 h-ai-c">${item.lastname}</div>
        <div class="table-col col-lg-3 h-ml-2 h-ai-c">${item.email}</div>
        <div class="table-col col-lg-2 h-ml-2 h-ai-c"><img src="/client/images/check.svg" alt="Check" class="icon icon--xs ${acceptance1}"></div>
        <div class="table-col col-lg-2 h-ml-2 h-ai-c"><img src="/client/images/check.svg" alt="Check" class="icon icon--xs ${acceptance2}"></div>
        <div class="table-col col-lg-2 h-ml-2 h-ai-c"><img src="/client/images/check.svg" alt="Check" class="icon icon--xs ${acceptance3}"></div>
        <div class="table-col col-lg-2 h-ml-2 h-ai-c"><img src="/client/images/check.svg" alt="Check" class="icon icon--xs ${acceptance4}"></div>
      `
      return row
    }

    const renderedData = data.items.map(item => {
      return render(item)
    })
    return renderedData
  }

  new Pagination(".js-table-users-rodo", {
    fetchApi: "/admin/api/users",
    renderPlace: ".js-table-content",
    paginationDOM: ".js-pagination",
    renderCallback: callback
  })
} catch (err) {}

/* -------------------------------------------------------------------------- */
/*                                  USERS TABLE                               */
/* -------------------------------------------------------------------------- */
try {
  const callback = data => {
    const render = item => {
      let disciplines = []
      if (item.disciplines) item.disciplines.forEach(d => disciplines.push(`<img src="/${d.image}" alt="dyscyplina" class="icon icon--xs h-mr-1 h-mb-1">`))
      if (disciplines.length == 0) disciplines.push("Brak")

      let donated = item.donated ? "" : "is-inactive"
      let active = item.active ? "" : "is-inactive"

      const row = document.createElement("div")
      row.classList = "table-row"
      row.id = item._id
      row.innerHTML = `
        <div class="table-col col-lg-1 h-ai-c">${item.number || ""}</div>
        <div class="table-col col-lg-1 h-ml-2 h-ai-c h-of-h">${item.firstname}</div>
        <div class="table-col col-lg-2 h-ml-2 h-ai-c h-of-h">${item.lastname}</div>
        <div class="table-col col-lg-1 h-ml-2 h-ai-c h-of-h">${item.donatedAmount || ""}</div>
        <div class="table-col col-lg-1 h-ml-2 h-ai-c h-of-h">${item.date ? moment(item.date).format("DD.MM.YYYY") : ""}</div>
        <div class="table-col col-lg-1 h-ml-2 h-ai-c h-of-h">${item.donatedDate ? moment(item.donatedDate).format("DD.MM.YYYY") : ""}</div>
        <div class="table-col col-lg-1 h-ml-2 h-ai-c h-of-h"><img src="/client/images/check.svg" alt="Check" class="icon icon--xs ${donated}"></div>
        <div class="table-col col-lg-1 h-ml-2 h-ai-c h-of-h"><img src="/client/images/check.svg" alt="Check" class="icon icon--xs ${active}"></div>
        <div class="table-col col-lg-1 h-ml-2 h-ai-c h-of-h">${item.phone || ""}</div>
        <div class="table-col col-lg-2 h-ml-2 h-ai-c h-of-h">${item.email}</div>
        <div class="table-col col-lg-2 h-ml-2 h-ai-c h-of-h">${item.unit}</div>
        <div class="table-col col-lg-1 h-ml-2 h-ai-c h-flex h-flex-w">${disciplines.join(" ")}</div>
        <div class="table-col col-lg-1 h-ml-2 h-ai-c">${item.category || ""}</div>
        <div class="table-col col-lg-3 h-ml-2 h-flex">
          <div class="col-md-6">
            <a href="/admin/uczestnicy/${item._id}" class="btn btn--red h-fw">Edytuj</a>
          </div>
          <div class="col-md-6 h-ml-2 h-flex-1">
            <div class="btn btn--red h-fw" onclick="removeUser(this, '${item._id}')">Usuń</div>
          </div>
        </div>
      `
      return row
    }

    const renderedData = data.items.map(item => {
      return render(item)
    })
    return renderedData
  }

  const users = new Pagination(".js-table-users", {
    fetchApi: "/admin/api/users",
    renderPlace: ".js-table-content",
    paginationDOM: ".js-pagination",
    advancedPagination: true,
    renderCallback: callback
  })
} catch (err) {}

// /* -------------------------------------------------------------------------- */
// /*                                  STATISTICS                                */
// /* -------------------------------------------------------------------------- */
// try {
//   const callback = data => {
//     const render = item => {
//       let disciplines = []
//       if (item.disciplines) item.disciplines.forEach(d => disciplines.push(`<img src="/${d.image}" alt="dyscyplina" class="icon icon--xs h-mr-1 h-mb-1">`))
//       if (disciplines.length == 0) disciplines.push("Brak")

//       let donated = item.donated ? "" : "is-inactive"

//       const row = document.createElement("div")
//       row.classList = "table-row"
//       row.id = item._id
//       row.innerHTML = `
//         <div class="table-col col-lg-1 h-ml-2 h-ai-c h-of-h">${item.unit}</div>
//         <div class="table-col col-lg-2 h-ml-2 h-ai-c h-of-h">${item.lastname}</div>
//         <div class="table-col col-lg-1 h-ml-2 h-ai-c h-of-h">${item.donatedAmount || ""}</div>
//         <div class="table-col col-lg-1 h-ml-2 h-ai-c h-of-h">${item.date ? moment(item.date).format("DD.MM.YYYY") : ""}</div>
//         <div class="table-col col-lg-1 h-ml-2 h-ai-c h-of-h">${item.donatedDate ? moment(item.donatedDate).format("DD.MM.YYYY") : ""}</div>
//         <div class="table-col col-lg-1 h-ml-2 h-ai-c h-of-h"><img src="/client/images/check.svg" alt="Check" class="icon icon--xs ${donated}"></div>
//         <div class="table-col col-lg-1 h-ml-2 h-ai-c h-of-h">${item.phone || ""}</div>
//         <div class="table-col col-lg-2 h-ml-2 h-ai-c h-of-h">${item.email}</div>
//         <div class="table-col col-lg-3 h-ml-2 h-ai-c h-of-h">${item.unit}</div>
//         <div class="table-col col-lg-1 h-ml-2 h-ai-c h-flex h-flex-w">${disciplines.join(" ")}</div>
//         <div class="table-col col-lg-1 h-ml-2 h-ai-c">${item.category}</div>
//       `
//       return row
//     }

//     const renderedData = data.items.map(item => {
//       return render(item)
//     })
//     return renderedData
//   }

//   const users = new Pagination(".js-table-statistics", {
//     fetchApi: "/admin/api/statistics",
//     renderPlace: ".js-table-content",
//     paginationDOM: ".js-pagination",
//     renderCallback: callback
//   })
// } catch (err) {}

/* -------------------------------------------------------------------------- */
/*                                JUDGES TABLE                                */
/* -------------------------------------------------------------------------- */
try {
  const callback = data => {
    const render = item => {
      let disciplinesImage = ""
      if (item.disciplines && item.disciplines.length > 0) {
        item.disciplines.forEach(x => {
          disciplinesImage += `<img src="/${x.image}" class="icon icon--xs" style="margin:3px" alt="${x.title}">`
        })
      }
      // let disciplineImage = item.discipline ? `<img src="/${item.discipline.image}" class="icon icon--xs" alt="${item.discipline.title}">` : ""
      item.superJudge ? (disciplinesImage = "Super Sędzia") : null

      const row = document.createElement("div")
      row.classList = "table-row"
      row.id = item._id
      row.innerHTML = `
        <div class="table-col col-lg-1 h-ai-c">${item.number}</div>
        <div class="table-col col-lg-3 h-ml-2 h-ai-c">${item.firstname}</div>
        <div class="table-col col-lg-3 h-ml-2 h-ai-c">${item.lastname}</div>
        <div class="table-col col-lg-2 h-ml-2 h-ja-c h-flex h-flex-w">${disciplinesImage}</div>
        <div class="table-col col-lg-3 h-ml-2 h-ai-c">${item.email}</div>
        <div class="table-col col-lg-3 h-ml-2 h-ai-c"><img src="/client/images/eye.svg" class="icon icon--xs h-hover" onclick="previewPassword(this)"><input type="password" class="readonly h-ml-2" readonly autocomplete='false' value="${item.grantedPassword}"/></div>
        <div class="table-col col-lg-4 h-ml-2 h-flex h-ai-c">
          <div class="col-md-6">
            <a href="/admin/sedziowie/${item._id}" class="btn btn--red h-fw">Edytuj</a>
          </div>
          <div class="col-md-6 h-ml-2 h-flex-1">
            <div class="btn btn--red h-fw" onclick="removeJudge(this, '${item._id}')">Usuń</div>
          </div>
        </div>`
      return row
    }

    const renderedData = data.items.map(item => {
      return render(item)
    })
    return renderedData
  }

  const users = new Pagination(".js-table-judges", {
    fetchApi: "/api/judges",
    renderPlace: ".js-table-content",
    paginationDOM: ".js-pagination",
    renderCallback: callback
  })
} catch (err) {}

/* -------------------------------------------------------------------------- */
/*                                 UNITS TABLE                                */
/* -------------------------------------------------------------------------- */
try {
  const callback = data => {
    const render = item => {
      const row = document.createElement("div")
      row.classList = "table-row"
      row.id = item._id
      row.innerHTML = `
        <div class="table-col col-lg-9 h-ai-c">${item.title}</div>
        <div class="table-col col-lg-3 h-ml-2 h-ai-c">${item.distance || "brak"}</div>
        <div class="table-col col-lg-6 h-ml-2 h-flex h-ai-c">
          <div class="col-md-6">
            <a href="/admin/urzedy-skarbowe/${item._id}" class="btn btn--red h-fw">Edytuj</a>
          </div>
          <div class="col-md-6 h-ml-2 h-flex-1">
            <div class="btn btn--red h-fw" onclick="removeUnit(this, '${item._id}')">Usuń</div>
          </div>
        </div>`
      return row
    }

    const renderedData = data.items.map(item => {
      return render(item)
    })
    return renderedData
  }

  const users = new Pagination(".js-table-units", {
    fetchApi: "/api/units",
    renderPlace: ".js-table-content",
    paginationDOM: ".js-pagination",
    renderCallback: callback
  })
} catch (err) {}

/* -------------------------------------------------------------------------- */
/*                           UNITS KILOMETERS TABLE                           */
/* -------------------------------------------------------------------------- */
try {
  const callback = data => {
    const render = (item, index) => {
      const row = document.createElement("div")
      row.classList = "table-row"
      row.id = item._id
      row.innerHTML = `
        <div class="table-col col-lg-1 h-ai-c">${index + 1}</div>
        <div class="table-col col-lg-8 h-ai-c">${item.title}</div>
        <div class="table-col col-lg-3 h-ml-2 h-ai-c">${item.peopleCount || "0"}</div>
        <div class="table-col col-lg-4 h-ml-2 h-ai-c">${item.distance || "brak"}</div>
        <div class="table-col col-lg-2 h-ml-2 h-ai-c">${item.score || "0"}</div>
        `
      return row
    }

    const renderedData = data.items.map((item, index) => {
      return render(item, index)
    })
    return renderedData
  }

  new Pagination(".js-table-unitsKilometers", {
    fetchApi: "/api/units-scores",
    renderPlace: ".js-table-content",
    paginationDOM: ".js-pagination",
    renderCallback: callback
  })
} catch (err) {}

/* -------------------------------------------------------------------------- */
/*                               SCORES GENERAL                               */
/* -------------------------------------------------------------------------- */
try {
  const callback = data => {
    const render = (item, index) => {
      const row = document.createElement("div")
      row.classList = "table-row"
      row.id = item._id
      row.innerHTML = `
        <div class="table-col col-lg-1 h-ai-c">${index + 1}</div>
        <div class="table-col col-lg-15 h-ml-2 h-ai-c">${item.title}</div>
        <div class="table-col col-lg-2 h-ml-2 h-ai-c">${item.score || "0"}</div>
        `
      return row
    }

    const renderedData = data.items.map((item, index) => {
      return render(item, index)
    })
    return renderedData
  }

  new Pagination(".js-table-scores-general", {
    fetchApi: "/api/scores/general-classification",
    renderPlace: ".js-table-content",
    paginationDOM: ".js-pagination",
    renderCallback: callback
  })
} catch (err) {}

/* -------------------------------------------------------------------------- */
/*                              SCORES INDIVIDUAL                             */
/* -------------------------------------------------------------------------- */
try {
  const callback = data => {
    const render = (item, index) => {
      let canEdit = false
      const href = window.location.href
      if ((href.indexOf("/sedzia") > -1 || href.indexOf("/admin") > -1) && href.indexOf("editable=1") > -1) canEdit = true

      let place = item.disciplines.place || "-"
      let pointsDescription = item.disciplines.pointsDescription || ""

      if (canEdit)
        place = `
      <div class="form-group form-text">
        <input type="number" style='min-width:84px' min="0" name="place" onchange="this.parentNode.parentNode.querySelector('.btn').classList.add('has-changed')" data-userId="${
          item._id
        }" data-disciplineSlug="${item.disciplines.slug}" value="${item.disciplines.place || " "}">
      </div>
      <div class="form-group form-text h-ml-1">
        <input ${
          ["badminton", "tenis-stolowy", "tenis-ziemny"].includes(item.disciplines.slug) ? "disabled" : null
        } type="text" placeholder="Wynik" style='min-width:84px' onchange="this.parentNode.parentNode.querySelector('.btn').classList.add('has-changed')" name="pointsDescription" data-userId="${
          item._id
        }" data-disciplineSlug="${item.disciplines.slug}" value="${item.disciplines.pointsDescription || ""}">
      </div>
      <div class="btn col-auto h-ml-2" onclick="updateUserPlace(this)">Zapisz</div>`

      const row = document.createElement("div")
      row.classList = "table-row"
      row.id = item._id
      row.innerHTML = `
        <div class="table-col ${canEdit ? "col-lg-7" : "col-lg-1"} h-ai-c">${place}</div>
        <div class="table-col h-ml-2 ${canEdit ? "hidden" : "col-lg-1"} h-ai-c">${pointsDescription}</div>
        <div class="table-col ${canEdit ? "col-lg-2" : "col-lg-3"} h-ml-2 h-ai-c">${item.firstname}</div>
        <div class="table-col col-lg-3 h-ml-2 h-ai-c">${item.lastname}</div>
        <div class="table-col col-lg-7 h-ml-2 h-ai-c">${item.unit}</div>
        <div class="table-col col-lg-2 h-ml-2 h-ai-c">${item.disciplines.points || 0}</div>
        `
      return row
    }

    const renderedData = data.items.map((item, index) => {
      return render(item, index)
    })
    return renderedData
  }

  new Pagination(".js-table-scores-individual", {
    fetchApi: "/api/scores/individual-classification",
    renderPlace: ".js-table-content",
    paginationDOM: ".js-pagination",
    renderCallback: callback
  })
} catch (err) {}

/* -------------------------------------------------------------------------- */
/*                                 SCORES GROUP                               */
/* -------------------------------------------------------------------------- */
try {
  const callback = data => {
    const render = (item, index) => {
      let canEdit = false
      const href = window.location.href
      if ((href.indexOf("/sedzia") > -1 || href.indexOf("/admin") > -1) && href.indexOf("editable=1") > -1) canEdit = true

      let place = "-"
      if (href.indexOf("discipline=pilka-nozna") > -1 || href.indexOf("discipline=pilka-siatkowa") > -1 || href.indexOf("discipline=przeciaganie-liny") > -1) {
        place = item.disciplines.place ? item.disciplines.place : "-"
      } else {
        place = index + 1
      }

      if (canEdit)
        place = `
      <div class="form-group form-text">
        <input type="number" style='min-width:84px' min="0" name="place" onchange="this.parentNode.parentNode.querySelector('.btn').classList.add('has-changed')" data-unitId="${
          item._id
        }" data-disciplineSlug="${item.disciplines.slug}" value="${item.disciplines.place || " "}">
      </div>
      <div class="btn col-auto h-ml-2" onclick="updateUnitPlace(this)">Zapisz</div>`

      const row = document.createElement("div")
      row.classList = "table-row"
      row.id = item._id
      row.innerHTML = `
        <div class="table-col ${canEdit ? "col-lg-4" : "col-lg-2"} h-ai-c">${place}</div>
        <div class="table-col ${canEdit ? "col-lg-12" : "col-lg-14"} h-ml-2 h-ai-c">${item.title}</div>
        <div class="table-col col-lg-2 h-ml-2 h-ai-c">${item.disciplines.points || 0}</div>
        `
      return row
    }

    const renderedData = data.items.map((item, index) => {
      return render(item, index)
    })
    return renderedData
  }

  new Pagination(".js-table-scores-group", {
    fetchApi: "/api/scores/group-classification",
    renderPlace: ".js-table-content",
    paginationDOM: ".js-pagination",
    renderCallback: callback
  })
} catch (err) {}

/* -------------------------------------------------------------------------- */
/*                                  MESSAGES                                  */
/* -------------------------------------------------------------------------- */
try {
  const callback = data => {
    const render = item => {
      const div = document.createElement("div")
      div.id = item._id
      div.classList = "table-row"
      div.innerHTML = `
        <div class="table-col col-lg-4 h-ai-c">${moment(item.createdAt).format("DD.MM.YYYY")}</div>
        <div class="table-col col-lg-8 h-ml-2 h-ai-c"><a href="/wiadomosci/${item._id}">${item.title}</a></div>
      `
      return div
    }

    const renderedData = data.items.map(item => {
      return render(item)
    })
    return renderedData
  }

  new Pagination(".js-table-messages", {
    fetchApi: "/api/messages",
    renderPlace: ".js-table-content",
    paginationDOM: ".js-pagination",
    renderCallback: callback
  })
} catch (err) {
  console.log(err)
}

/* -------------------------------------------------------------------------- */
/*                             UNIT USERS TABLE                               */
/* -------------------------------------------------------------------------- */
try {
  const callback = data => {
    const render = item => {
      const row = document.createElement("div")
      row.classList = "table-row"
      row.id = item._id
      let disciplines = []
      if (item.disciplines) item.disciplines.forEach(d => disciplines.push(`<img src="/${d.image}" alt="dyscyplina" class="icon icon--xs h-mr-1 h-mb-1">`))
      if (disciplines.length == 0) disciplines.push("Brak")
      row.innerHTML = `
        <div class="table-col col-lg-1 h-ai-c">${item.number}</div>
        <div class="table-col col-lg-5 h-ml-2 h-ai-c">${item.firstname}</div>
        <div class="table-col col-lg-5 h-ml-2 h-ai-c">${item.lastname}</div>
        <div class="table-col col-lg-4 h-ml-2 h-ai-c h-flex h-flex-w">${disciplines.join(" ")}</div>
        <div class="table-col col-lg-2 h-ml-2 h-ai-c">${item.category}</div>
      `
      return row
    }

    const renderedData = data.items.map(item => {
      return render(item)
    })
    return renderedData
  }

  const usersInDiscipline = new Pagination(".js-table-my-unit", {
    fetchApi: "/api/unit-users",
    renderPlace: ".js-table-content",
    paginationDOM: ".js-pagination",
    renderCallback: callback
  })
} catch (err) {}
